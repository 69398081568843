import { searchReducer,initialSearchState } from "../reducers/searchReducer";
import { createJobReducer, initialCreateJobState } from "../reducers/createJobReducer";
import { StateContext, DispatchContext } from '../utils/providers';
import { useReducer } from "react";
import { useCombinedReducer } from '../utils/useCombinedReducer';
import createTheme from '@mui/material/styles/createTheme';
import { ThemeProvider } from '@mui/material/styles/';
import createPalette from '@mui/material/styles/createPalette';
import { jobReducer, initialJobState } from "../reducers/jobReducer";

const GlobalContextProvider = ({children}) => {
  const theme = createTheme({
    palette: createPalette({
      mode: 'dark',
      primary: {
        main: '#0073de',
      },
      secondary: {
        main: '#E3008C',
      },
    }),
  });
  
  const combinedReducers = {
    search: useReducer(searchReducer, initialSearchState),
    createJob: useReducer(createJobReducer, initialCreateJobState),
    job: useReducer(jobReducer, initialJobState)
  };
  
  const [state, dispatch] = useCombinedReducer(combinedReducers);
  
  return (
    <ThemeProvider theme={theme}>
      <DispatchContext.Provider value={dispatch}>
        <StateContext.Provider value={state}>
          {children}
        </StateContext.Provider>
      </DispatchContext.Provider>
    </ThemeProvider>
  );
};

export default GlobalContextProvider;