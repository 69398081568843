import Job from "../Entities/Job";

export type JobState = {
  uploadInProgress: boolean,
  uploadPercentage: number,
  uploadFailMessage?: string,
  jobsInCart?: Array<Job>
}

export const initialJobState: JobState = {
  uploadInProgress: false,
  uploadPercentage: 0,
}

export const jobReducer = (state:JobState, action: any) => {
  switch (action.type) {
    case 'ADD_JOB_TO_CART':
      return {
        ...state,
        jobsInCart: state.jobsInCart ? [...state.jobsInCart, action.job] : [action.job]
      }
    case 'REMOVE_JOB_FROM_CART':
      return {
        ...state,
        jobsInCart: state.jobsInCart?.filter((job) => { return job.jobId !== action.jobId })
      }
    case 'UPLOAD_IMAGE_START':
      return {
        ...state,
        uploadInProgress: true,
        uploadFailMessage: null,
        uploadPercentage: 0
      }
    case 'UPLOAD_IMAGE_FAIL':
      return {
        ...state,
        uploadInProgress: false,
        uploadFailMessage: action.error,
        uploadPercentage: 0
      }
    case 'UPLOAD_IMAGE_PROGRESS':
      return {
        ...state,
        uploadInProgress: true,
        uploadFailMessage: null,
        uploadPercentage: action.progress
      }
    case 'UPLOAD_IMAGE_SUCCESS':
      return {
        ...state,
        uploadInProgress: false,
        uploadFailMessage: null,
        uploadPercentage: 0
      }
    default:
      return state;
  }
};