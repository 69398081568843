import { initialSearchState } from "../reducers/searchReducer";
import { initialCreateJobState } from "../reducers/createJobReducer";
import { createContext } from "react";
import { initialJobState } from "../reducers/jobReducer";


export const DispatchContext = createContext(null);
export const StateContext = createContext({ 
  search: initialSearchState,
  createJob: initialCreateJobState,
  job: initialJobState
});
