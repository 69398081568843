import { nanoid } from "nanoid";
import Job from "../Entities/Job";

export type CreateJobState = {
  job: Job
  featured?: boolean
}

export const initialCreateJobState: CreateJobState = {
  job: {
    jobTitle: '',
    jobId: nanoid()
  }
}

export const createJobReducer = (state:CreateJobState, action: any) => {
  switch (action.type) {
    case 'UPDATE_JOB':
      return {
        ...state,
        job: action.job
      }
    case 'SET_FEATURED':
      return {
        ...state,
        featured: action.featured
      }
    case 'SET_JOB':
      return {
        ...state,
        job: action.job
      }
    default:
      return state;
  }
};