export const useCombinedReducer: any = (combinedReducers:any) => {
  // Global State
  let state: any = {};
  Object.keys(combinedReducers).forEach( (key) => {
    state[key] = combinedReducers[key][0];
  });
  
  // Global Dispatch Function
  const dispatch = (action: Function) =>
    Object.keys(combinedReducers)
      .map(key => combinedReducers[key][1])
      .forEach(fn => fn(action));
 
  return [state, dispatch];
};