export type SearchState = {
  searchString: string,
  
}

export const initialSearchState:SearchState = {
  searchString: ""
}
 
export const searchReducer = (state:SearchState, action: any) => {
  switch (action.type) {
    case 'SEARCH_UPDATE':
      return {
        ...state,
        searchString: action.searchString
      }
    default:
      return state;
  }
};